import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule, APP_BASE_HREF } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/Header/header.component';
import { HomeComponent } from "./Components/Home/home.component";
import { FooterComponent } from "./Components/footer/footer.component";
import { NavBarComponent } from "./Components/navbar/navbar.component";
import { AccountManagementComponent } from "./Components/AccountManagement/accountManagement.component";
import { ReviewMatchDataComponent } from "./Components/reviewMatchData/reviewMatchData.component";
import { ThanksComponent } from "./Components/Thanks/thanks.component";
import { UpdateComponent } from "./Components/Update/update.component";
import { RepairComponent } from "./Components/Repair/repair.component";


import { LoginService } from "./Service/login.service";
import { AuthService } from "./Service/auth.service";
import { UserService } from "./Service/user.service";
import { FormService } from "./Service/form.service";
import { ResourceService } from "./Service/resource.service";
import { AuthGuard} from "./Service/auth-guard.service"
import { appRoutingModule } from "./app.routing";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
      AppComponent,
      HeaderComponent,
      FooterComponent,
      HomeComponent,
      NavBarComponent,
      AccountManagementComponent,
      ReviewMatchDataComponent,
      ThanksComponent,
      UpdateComponent,
      RepairComponent
  ],
  imports: [
      BrowserModule, ReactiveFormsModule,
      FormsModule, HttpClientModule,
      CommonModule, BrowserAnimationsModule,
      appRoutingModule, MatIconModule,
      MatFormFieldModule, MatSelectModule,
      MatDialogModule, MatTableModule,
      MatInputModule, MatTabsModule,
      MatPaginatorModule, MatExpansionModule,
      MatCheckboxModule,
      NgbModule
  ],
    providers: [{ provide: APP_BASE_HREF, useValue: "/" }, LoginService, AuthService, UserService, FormService, ResourceService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
