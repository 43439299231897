import { Component } from '@angular/core';

@Component({
    selector: 'app-thanks',
    templateUrl: 'Template/update.component.html',
    styleUrls: ['Style/update.component.css']
})
export class UpdateComponent {

    constructor() { }

}