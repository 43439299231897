//info page if you want to take the page down and it will show a message for users.

import { Component } from '@angular/core';

@Component({
    selector: 'app-repair',
    templateUrl: 'Template/repair.component.html',
    styleUrls: ['Style/repair.component.css']
})
export class RepairComponent {

    constructor() { }

}