//navbar
//after user has logged in navbar will be shown.
//user details is stored in local storage and is availble to be used.

import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../Service/auth.service";
import { LoginService } from "../../Service/login.service";

@Component({
  selector: 'app-navbar',
  templateUrl: 'Template/navbar.component.html',
  styleUrls: ['Style/navbar.component.css']
})
export class NavBarComponent implements OnInit {

  isLoggedIn: boolean = false;
    isAdminUser: boolean = false;
    isCentreUser: boolean = false;

  constructor(private authService: AuthService,
                private loginService: LoginService) {
    this.isLoggedIn = !!loginService.getLoggedInStatus.subscribe((loggedIn: boolean) => this.changeLoggedInStatus(loggedIn));
  }
  logout(): void {
    this.authService.logout();
    this.loginService.getLoggedInStatus.emit(false);
  }

  usersRole: string;

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.changeLoggedInStatus(true);
    } else {
      this.changeLoggedInStatus(false);
    }
  }

  private changeLoggedInStatus(loggedIn: boolean): void {

    if (loggedIn === false) {
        this.isAdminUser = false;
        this.isCentreUser = false;
      this.isLoggedIn = loggedIn;
    }

    if (loggedIn) {
      this.isLoggedIn = true;
      this.usersRole = JSON.parse(sessionStorage.getItem("currentUser"))["userRole"];

      if (this.usersRole === "Admin") {
          this.isAdminUser = true;
      } else {
          if (this.usersRole === "Centre") {
              this.isAdminUser = false;
              this.isCentreUser = true;
          }
      }
    }

  }
}
