import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { IUser } from "../../Model/user.model";
import { ResourceService } from "../../Service/resource.service";
import { ICentre } from "../../Enum/centre.model";
import { IRole } from "../../Enum/role.model";
import { AuthService } from "../../Service/auth.service";
import { UserService } from "../../Service/user.service";
import { LoginService } from "../../Service/login.service";
import { DbOperation } from "../../Shared/DbOperation.enum";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-accountManagement',
    templateUrl: 'Template/accountManagement.component.html',
    styleUrls: ['Style/accountManagement.component.css']

})
export class AccountManagementComponent implements OnInit {

    constructor(private formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private authService: AuthService,
        private userService: UserService,
        private modalService: NgbModal) {
        this.createUserFrm();
    }

    adminUsers: IUser[];
    users: IUser[];
    terminatedUsers: IUser[];
    user: IUser;
    reviewer: IUser;
    reviewers: IUser[];
    centreUser: IUser;
    centreUsers: IUser[];

    roles: IRole[];
    centres: ICentre[];

    isLoading: boolean = false;
    isEditing: boolean = false;
    closeResult: string;

    userFrm: any;
    msg: string;
    modelMsg: string;
    modalTitle: string;
    modalBtnTitle: string;
    dbops: DbOperation;
    hideEditTerminateUserButtons: boolean;

    activeModal: NgbActiveModal;

    ngOnInit(): void {
        this.isLoading = true;
        this.getUsers();
        this.getRole();
        this.getCentres();
        this.isLoading = false;
    }

    createUserFrm() {
        this.userFrm = this.formBuilder.group({
            EmailAddress: ['', [Validators.required]],
            Role: [{}],
            Centre: [{}],
            IsActive: [Boolean]
        });
    }

    getUsers() {
        this.userService.getUsers().subscribe(
            users => {
                this.adminUsers = [];
                this.users = [];
                this.centreUsers = [];
                this.reviewers = [];
                this.terminatedUsers = [];

                for (var i = 0; i < users.length; i++) {

                    if (users[i].IsActive) {

                        switch (users[i].Role.Description) {
                            case "Admin":
                                this.adminUsers.push(users[i]);
                                break;
                            case "Centre":
                                this.centreUsers.push(users[i]);
                                break;
                            case "Reviewer":
                                this.reviewers.push(users[i]);
                                break;
                        }
                    } else {
                        this.terminatedUsers.push(users[i]);
                    }
                }
            },
            error => this.msg = <any>error);
    }

    getUser() {
        if (this.authService.isLoggedIn()) {
            var email = JSON.parse(sessionStorage.getItem("currentUser"))["username"];

            this.userService.getUser(email).subscribe(
                data => {
                    this.user = data;

                    this.userFrm.setValue({
                        EmailAddress: this.user.EmailAddress,
                        Role: this.user.Role,
                        Centre: this.user.Centre,
                        IsActive: this.user.IsActive
                    });

                },
                error => this.msg = <any>error);
        }
    }

    getCentres() {
        this.resourceService.getCentres().subscribe(
            data => {
                this.centres = data;
            },
            error => this.msg = <any>error);
    }

    getRole() {
        this.resourceService.getRoles().subscribe(
            data => {
                this.roles = data;
            },
            error => this.msg = <any>error);
    }
    
    addUser(content: any) {
        this.isEditable(true);
        this.createUserFrm();
        this.modalTitle = "Add User";
        this.dbops = DbOperation.Create;
        this.hideEditTerminateUserButtons = true;
        this.activeModal = this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", keyboard: false });
    }

    viewUser(content: any, user: any) {
        this.modalTitle = "User Management";
        this.isEditable(false);
        this.hideEditTerminateUserButtons = false;
        this.user = user;
        this.userFrm.setValue({
            EmailAddress: this.user.EmailAddress,
            Role: this.user.Role,
            Centre: this.user.Centre,
            IsActive: this.user.IsActive
        });
        this.dbops = DbOperation.Update;
        this.activeModal = this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", keyboard: false });
    }

    editUser() {
        this.isEditable(true);
        this.dbops = DbOperation.Update;
    }

    terminateUser(content: any, model: IUser) {
        this.activeModal.close();
        this.dbops = DbOperation.Terminate;
        this.userFrm.setValue({
            EmailAddress: model.EmailAddress,
            Role: model.Role,
            Centre: model.Centre,
            IsActive: model.IsActive
        });
        this.activeModal = this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", keyboard: false });
    }

    activateUser(content: any, model: IUser) {
        this.activeModal.close();
        this.dbops = DbOperation.Activate;
        this.userFrm.setValue({
            EmailAddress: model.EmailAddress,
            Role: model.Role,
            Centre: model.Centre,
            IsActive: model.IsActive
        });
        this.activeModal = this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", keyboard: false });
    }

    onSubmit(formData: any) {
        switch (this.dbops) {
            case DbOperation.Create:
                this.userService.addUser(formData.value).subscribe(
                    (data) => {
                        if (data.Successful) {
                            this.msg = "User successfully added";
                            this.getUsers();
                            this.activeModal.close();
                        } else {
                            this.modelMsg = data.Information;
                        }
                    },
                    error => {
                        this.modelMsg =
                            "Error has occured adding user, please contact system administrators: " + error;
                    }
                );
                break;
            case DbOperation.Update:
                this.userService.updateUser(formData.value).subscribe(
                    (data) => {
                        if (data.Successful) {
                            this.msg = "User successfully updated";
                            this.getUsers();
                            this.activeModal.close();
                        } else {
                            this.modelMsg = data.Information;
                        }
                    },
                    error => {
                        this.modelMsg =
                            "Error has occured adding user, please contact system administrators: " + error;
                    }
                );
                break;
            case DbOperation.Terminate:
                this.userService.terminateUser(formData).subscribe(
                    (data): void => {
                        if (!data.Successful) {
                            this.modelMsg = data.Information;
                        } else {
                            this.msg = data.Information;
                            this.getUsers();
                            this.activeModal.close();
                        }
                    },
                    error => {
                        this.modelMsg =
                            "Error has occured terminating user, please contact system administrators: " + error;
                    }
                );
                break;
            case DbOperation.Activate:
                this.userService.activateUser(formData).subscribe(
                    (data): void => {
                        if (!data.Successful) {
                            this.modelMsg = data.Information;
                        } else {
                            this.msg = data.Information;
                            this.getUsers();
                            this.activeModal.close();
                        }
                    },
                    error => {
                        this.modelMsg =
                            "Error has occured activating user, please contact system administrators: " + error;
                    }
                );
                break;
        }
        this.getUsers();
    }

    open(content: any) {
        this.activeModal =
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: "static", keyboard: false });
    }

    isEditable(isEnable: boolean) {
        if (isEnable) {
            this.userFrm.enable();
            this.isEditing = true;
        } else {
            this.userFrm.disable();
            this.isEditing = false;
        }
    }

    compare(val1: any, val2: any) {
        try {
           var check = val1.Name === val2.Name
        }
        catch (error) {
            alert(error.message);
        }
        return check;
    }
}

