import { Component } from '@angular/core';

@Component({
    selector: 'app-thanks',
    templateUrl: 'Template/thanks.component.html',
    styleUrls: ['Style/Thanks.component.css']
})
export class ThanksComponent {

    constructor() { }

}