import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Global } from "../../app/Shared/global";
import { LoginService } from './login.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AuthService {
    public token: string;
    public userRole: string;
    public username: string;
    public userKey: string;
    public userCentre: string;

    private authServiceBaseUrl = Global.BASE_LOGIN_ENDPOINT;
    private loginUrl = this.authServiceBaseUrl + "login";

    constructor(private http: HttpClient,
        private loginService: LoginService,
        private route: Router) {
        var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
        this.userRole = currentUser && currentUser.userRole;
        this.userCentre = currentUser && currentUser.userCentre;
        this.username = currentUser && currentUser.username;
        this.userKey = currentUser && currentUser.userKey;
        
    }

    login(formData: any) {
        return this.http.post<any>(this.loginUrl, formData, httpOptions)
            .pipe(
                tap((data: any) => this.log(`User logged in`)),
                catchError(this.handleError<any>('login'))
            );
    }

    logout(): void {
        this.token = null;
        sessionStorage.removeItem('currentUser');
        this.loginService.isLoggedIn;
        this.route.navigate(['/']);
    }

    isLoggedIn(): Observable<boolean> {
        var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser) {
            return (true) as any;  
        }
        return (false) as any;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }
}