import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Global } from "../Shared/global";
import { IUser } from '../Model/user.model';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    private userBaseUrl = Global.BASE_USER_ENDPOINT;
    private addUserUrl = this.userBaseUrl + "adduser";
    private updateUserUrl = this.userBaseUrl + "updateuser";
    private activateUserUrl = this.userBaseUrl + "activateuser";
    private deleteUserUrl = this.userBaseUrl + "terminateuser";
    private getUsersUrl = this.userBaseUrl + "getUsers";
    private getUserByUrl = this.userBaseUrl + "getuserby";

    addUser(user: IUser): Observable<any> {
        return this.http.post<IUser>(this.addUserUrl, user, httpOptions)
            .pipe(
                catchError(this.handleError('addUser'))
            );
    }

    updateUser(user: IUser): Observable<any> {
        return this.http.post<IUser>(this.updateUserUrl, user, httpOptions)
            .pipe(
                catchError(this.handleError('updateUser'))
            );
    }

    terminateUser(user: IUser): Observable<any> {
        return this.http.post<IUser>(this.deleteUserUrl, user, httpOptions)
            .pipe(
                catchError(this.handleError('deleteUser'))
            );
    }

    activateUser(user: IUser): Observable<any> {
        return this.http.post<IUser>(this.activateUserUrl, user, httpOptions)
            .pipe(
                catchError(this.handleError('activateUser'))
            );
    }

    getUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>(this.getUsersUrl)
            .pipe(
                catchError(this.handleError('getUsers', []))
            );
    }

    getUser(email: string): Observable<IUser> {
        return this.http.post<IUser>(this.getUserByUrl, JSON.stringify(email), httpOptions)
            .pipe(
                catchError(this.handleError<IUser>('getuserby'))
            );
    }

    get(url: string) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get(url, { headers: headers, observe: 'response' });
    }


    post(url: string, model: any) {
        let body = JSON.stringify(model);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(url, body, { headers: headers, observe: 'response' });
    }

    postStr(url: string, string: string) {
        let body = JSON.stringify(string);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(url, body, { headers: headers, observe: 'response' });
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }
}