//reviewer data
//data page for centre staff to look at reviewers data. Only fields with information in the boxes will show up. 
//export is availible for staff to place the data into excel.
//data is also availible in qlikview speak to data team
//all data is pulled back from database and a table will show the individual users. Once you click on a user a box opens with the data availible a note box 
//is also there to add notes to.
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICredentials } from "../../Model/credentials";
import { MatExpansionModule, MatInputModule } from '@angular/material';
import { Observable, of } from 'rxjs';
import { ApplicantDetails } from "../../model/applicantDetails.model";
import { ReviewerData } from "../../model/reviewerData.model";
import { ExportReviewer } from "../../model/exportReviewer.model";
import { AuthService } from "../../Service/auth.service";
import { FormService } from '../../Service/form.service';
import { ResourceService } from '../../Service/resource.service';
import { Global } from "../../Shared/global";



@Component({
    selector: 'app-reviewMatchData',
    templateUrl: 'Template/reviewMatchData.component.html',
    styleUrls: ['Style/reviewMatchData.component.css']
})
export class ReviewMatchDataComponent implements OnInit {

    constructor(private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private authService: AuthService,
        private resourceService: ResourceService,
        private formService: FormService,
        private modalService: NgbModal,
        private router: Router) {

        this.createReviewerDataFrm();

    }

    // Modals
    reviewerInfo: ReviewerData = new ReviewerData;
    reviewerInfos: ReviewerData[];
    terminatedReviewers: ReviewerData[];
    exportReviewer: ExportReviewer;
    exportReviewers: ExportReviewer[] = [];
    filteredReviewerInfos: ReviewerData[];

    // General variables
    reviewerDataFrm: any;
    User: any;
    msg: string;
    modalMsg: string;
    modalTitle: string;
    userKey: any;
    userRole: any;
    userCentre: any;
    loading: boolean = true;
    isReadOnly: boolean = true;
    isDisabled: boolean = true;
    isLocked: boolean = true;

    //show accordian sections if data present, reduces page size
    showBlood: boolean = false;
    showCancer: boolean = false;
    showCardio: boolean = false;
    showCongential: boolean = false;
    showEar: boolean = false;
    showEye: boolean = false;
    showInfection: boolean = false;
    showInflammatoryAndImmune: boolean = false;
    showInjuriesAndImmune: boolean = false;
    showMentalHealth: boolean = false;
    showMetabolic: boolean = false;
    showMusculoSkeletal: boolean = false;
    showNeurological: boolean = false;
    showOralAndGastro: boolean = false;
    showRenalandUro: boolean = false;
    showReproductive: boolean = false;
    showRespiratory: boolean = false;
    showSkin: boolean = false;
    showStoke: boolean = false;
    showGenericHealth: boolean = false;
    showOther: boolean = false;
    showGroups: boolean = false;
    showAnything: boolean = false;
    showSocialCare: boolean = false;
    showLifestyle: boolean = false;
    showOccupation: boolean = false;
    showCountry: boolean = false;
    showOtherCountry: boolean = false;
    response: boolean = false;



    activeModal: NgbActiveModal;

    ngOnInit(): void {
        if (this.authService.isLoggedIn()) {
            this.getUserInfo();
            this.reviewerData();
        } else {
            this.router.navigate([""]);
        }
    }

    reviewerData(): any {
        this.reviewerInfos = [];
        this.terminatedReviewers = [];

        if (this.userCentre == "CCF" || this.userRole == "Admin") {
            this.resourceService.reviewerData().subscribe(
                data1 => {
                    for (let reviewer of data1) {
                        if (reviewer.User.IsActive) {
                            this.reviewerInfos.push(reviewer);
                        }
                        else {
                            this.terminatedReviewers.push(reviewer);
                        }
                    }
                    
                    this.assignCopy();
                },
                error => this.msg = error);
        } else {
            this.resourceService.NETSCCData().subscribe(
                data2 => {
                    for (let reviewer of data2) {
                        if (reviewer.User.IsActive) {
                            this.reviewerInfos.push(reviewer);
                        }
                        else {
                            this.terminatedReviewers.push(reviewer);
                        }
                    }
                    this.assignCopy();
                    
                },
                error => this.msg = error);
        }
    }

    getUserInfo() {
        this.userKey = JSON.parse(sessionStorage.getItem("currentUser"))["userKey"];
        this.userRole = JSON.parse(sessionStorage.getItem("currentUser"))["userRole"];
        this.userCentre = JSON.parse(sessionStorage.getItem("currentUser"))["userCentre"];
    }

    //create modal
    createReviewerDataFrm(): any {
        this.reviewerDataFrm = this.formBuilder.group({
            ApplicantKey: [""],
            Title: ["", Validators.required],
            FirstName: ["", Validators.required],
            Surname: ["", Validators.required],
            YearOfBirth: ["", Validators.required],
            DependentChildren: ["", Validators.required],
            EmailAddress: ["", Validators.required],
            BloodPatient: "",
            BloodCarer: "",
            CancerPatient: "",
            CancerCarer: "",
            CardioPatient: "",
            CardioCarer: "",
            CongentialPatient: "",
            CongentialCarer: "",
            EarPatient: "",
            EarCarer: "",
            EyePatient: "",
            EyeCarer: "",
            InfectionPatient: "",
            InfectionCarer: "",
            InflammatoryAndImmunePatient: "",
            InflammatoryAndImmuneCarer: "",
            InjuriesAndAccidentsPatient: "",
            InjuriesAndAccidentsCarer: "",
            MentalHealthPatient: "",
            MentalHealthCarer: "",
            MetabolicAndEndocrimePatient: "",
            MetabolicAndEndocrimeCarer: "",
            MusculoSkeletalPatient: "",
            MusculoSkeletalCarer: "",
            NeurologicalPatient: "",
            NeurologicalCarer: "",
            OralAndGastroPatient: "",
            OralAndGastroCarer: "",
            RenalAndUrogenitalPatient: "",
            RenalAndUrogentialCarer: "",
            ReproductiveHealthAndChildbirthPatient: "",
            ReproductiveHealthAndChildbirthCarer: "",
            RespiratoryPatient: "",
            RespiratoryCarer: "",
            SkinPatient: "",
            SkinCarer: "",
            StrokePatient: "",
            StrokeCarer: "",
            GenericHealthPatient: "",
            GenericHealthCarer: "",
            OtherPatient: "",
            OtherCarer: "",
            Groups: "",
            AnythingElse: "",
            NotProfessionalTick: "",
            NewsAndEventsTick: "",
            ReviewApplicationTick: "",
            ShareDataCheckbox: "",
            YearOfBirthKey: "",
            CountryOfResidenceKey: "",
            OtherCountry: "",
            OccupationYesNo: "",
            PublicSector: "",
            Disability: "",
            Lifestyle: "",
            CurrentOccupation: "",
            CcfOnly: "",
            Notes: "",
            User: {},
            UpdatedDate: [""],
            CreatedDate: [""],
            ResponseFlag: [""]
        });
    }

    //view modal
    viewData(content: any, reviewerInfo: any) {
        this.createReviewerDataFrm();
        //this.reviewerInfo = this.reviewerInfos.filter(x => x === selected)[0];
        this.showAnything = false;
        this.showBlood = false;
        this.showCancer = false;
        this.showCardio = false;
        this.showCongential = false;
        this.showEar = false;
        this.showEye = false;
        this.showInfection = false;
        this.showInflammatoryAndImmune = false;
        this.showInjuriesAndImmune = false;
        this.showMentalHealth = false;
        this.showMetabolic = false;
        this.showMusculoSkeletal = false;
        this.showNeurological = false;
        this.showOralAndGastro = false;
        this.showRenalandUro = false;
        this.showReproductive = false;
        this.showRespiratory = false;
        this.showSkin = false;
        this.showStoke = false;
        this.showGenericHealth = false;
        this.showOther = false;
        this.showGroups = false;
        this.showSocialCare = false;
        this.showLifestyle = false;
        this.showOccupation = false;
        this.showCountry = false;
        this.showOtherCountry = false;
        this.reviewerInfo = reviewerInfo;
        this.updateReviewerDataFrm(reviewerInfo);
        if (reviewerInfo.BloodPatient || reviewerInfo.BloodCarer) { this.showBlood = true; }
        if (reviewerInfo.CancerPatient || reviewerInfo.CancerCarer) { this.showCancer = true; }
        if (reviewerInfo.CardioPatient || reviewerInfo.CardioCarer) { this.showCardio = true; }
        if (reviewerInfo.CongenitialPatient || reviewerInfo.CongenitialCarer) { this.showCongential = true; }
        if (reviewerInfo.EarPatient || reviewerInfo.EarCarer) { this.showEar = true; }
        if (reviewerInfo.EyePatient || reviewerInfo.EyeCarer) { this.showEye = true; }
        if (reviewerInfo.InfectionPatient || reviewerInfo.InfectionCarer) { this.showInfection = true; }
        if (reviewerInfo.InflammatoryAndImmunePatient || reviewerInfo.InflammatoryAndImmuneCarer) { this.showInflammatoryAndImmune = true; }
        if (reviewerInfo.InjuriesAndAccidentsPatient || reviewerInfo.InjuriesAndAccidentsCarer) { this.showInjuriesAndImmune = true; }
        if (reviewerInfo.MentalHealthPatient || reviewerInfo.MentalHealthCarer) { this.showMentalHealth = true; }
        if (reviewerInfo.MetabolicAndEndocrimePatient || reviewerInfo.MetabolicAndEndocrimeCarer) { this.showMetabolic = true; }
        if (reviewerInfo.MusculoSkeletalPatient || reviewerInfo.MusculoSkeletalCarer) { this.showMusculoSkeletal = true; }
        if (reviewerInfo.NeurologicalPatient || reviewerInfo.NeurologicalCarer) { this.showNeurological = true; }
        if (reviewerInfo.OralAndGastroPatient || reviewerInfo.OralAndGastroCarer) { this.showOralAndGastro = true; }
        if (reviewerInfo.RenalAndUrogenitalPatient || reviewerInfo.RenalAndUrogenitalCarer) { this.showRenalandUro = true; }
        if (reviewerInfo.ReproductiveHealthAndChildbirthPatient || reviewerInfo.ReproductiveHealthAndChildbirthCarer) { this.showReproductive = true; }
        if (reviewerInfo.RespiratoryPatient || reviewerInfo.RespiratoryCarer) { this.showRespiratory = true; }
        if (reviewerInfo.SkinPatient || reviewerInfo.SkinCarer) { this.showSkin = true; }
        if (reviewerInfo.StrokePatient || reviewerInfo.StrokeCarer) { this.showStoke = true; }
        if (reviewerInfo.GenericHealthPatient || reviewerInfo.GenericHealthCarer) { this.showGenericHealth = true; }
        if (reviewerInfo.OtherPatient || reviewerInfo.OtherCarer) { this.showOther = true; }
        if (reviewerInfo.Groups) { this.showGroups = true; }
        if (reviewerInfo.AnythingElse) { this.showAnything = true; }
        if (reviewerInfo.Disability) { this.showSocialCare = true; }
        if (reviewerInfo.Lifestyle) { this.showLifestyle = true; }
        if (reviewerInfo.CurrentOccupation) { this.showOccupation = true; }
        if (reviewerInfo.OtherCountry) { this.showOtherCountry = true; }
        if (reviewerInfo.CountryOfResidenceKey) { this.showCountry = true;}
        if (this.userRole === "Admin") { this.isDisabled = false; }
        if (this.userRole === "Admin") { this.isReadOnly = false; }
        this.activeModal = this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", keyboard: false, size: "lg" });
    }

    //update Form
    updateReviewerDataFrm(reviewerInfo) {
        this.reviewerDataFrm.patchValue({
            User: reviewerInfo.User,
            ApplicantKey: reviewerInfo.ApplicantKey,
            Title: reviewerInfo.Title,
            FirstName: reviewerInfo.FirstName,
            Surname: reviewerInfo.Surname,
            YearOfBirth: reviewerInfo.YearOfBirth,
            DependentChildren: reviewerInfo.DependentChildren,
            EmailAddress: reviewerInfo.User.EmailAddress,
            BloodPatient: reviewerInfo.BloodPatient,
            BloodCarer: reviewerInfo.BloodCarer,
            CancerPatient: reviewerInfo.CancerPatient,
            CancerCarer: reviewerInfo.CancerCarer,
            CardioPatient: reviewerInfo.CardioPatient,
            CardioCarer: reviewerInfo.CardioCarer,
            CongentialPatient: reviewerInfo.CongentialPatient,
            CongentialCarer: reviewerInfo.CongentialCarer,
            EarPatient: reviewerInfo.EarPatient,
            EarCarer: reviewerInfo.EarCarer,
            EyePatient: reviewerInfo.EyePatient,
            EyeCarer: reviewerInfo.EyeCarer,
            InfectionPatient: reviewerInfo.InfectionPatient,
            InfectionCarer: reviewerInfo.InfectionCarer,
            InflammatoryAndImmunePatient: reviewerInfo.InflammatoryAndImmunePatient,
            InflammatoryAndImmuneCarer: reviewerInfo.InflammatoryAndImmuneCarer,
            InjuriesAndAccidentsPatient: reviewerInfo.InjuriesAndAccidentsPatient,
            InjuriesAndAccidentsCarer: reviewerInfo.InjuriesAndAccidentsCarer,
            MentalHealthPatient: reviewerInfo.MentalHealthPatient,
            MentalHealthCarer: reviewerInfo.MentalHealthCarer,
            MetabolicAndEndocrimePatient: reviewerInfo.MetabolicAndEndocrimePatient,
            MetabolicAndEndocrimeCarer: reviewerInfo.MetabolicAndEndocrimeCarer,
            MusculoSkeletalPatient: reviewerInfo.MusculoSkeletalPatient,
            MusculoSkeletalCarer: reviewerInfo.MusculoSkeletalCarer,
            NeurologicalPatient: reviewerInfo.NeurologicalPatient,
            NeurologicalCarer: reviewerInfo.NeurologicalCarer,
            OralAndGastroPatient: reviewerInfo.OralAndGastroPatient,
            OralAndGastroCarer: reviewerInfo.OralAndGastroCarer,
            RenalAndUrogenitalPatient: reviewerInfo.RenalAndUrogenitalPatient,
            RenalAndUrogentialCarer: reviewerInfo.RenalAndUrogentialCarer,
            ReproductiveHealthAndChildbirthPatient: reviewerInfo.ReproductiveHealthAndChildbirthPatient,
            ReproductiveHealthAndChildbirthCarer: reviewerInfo.ReproductiveHealthAndChildbirthCarer,
            RespiratoryPatient: reviewerInfo.RespiratoryPatient,
            RespiratoryCarer: reviewerInfo.RespiratoryCarer,
            SkinPatient: reviewerInfo.SkinPatient,
            SkinCarer: reviewerInfo.SkinCarer,
            StrokePatient: reviewerInfo.StrokePatient,
            StrokeCarer: reviewerInfo.StrokeCarer,
            GenericHealthPatient: reviewerInfo.GenericHealthPatient,
            GenericHealthCarer: reviewerInfo.GenericHealthCarer,
            OtherPatient: reviewerInfo.OtherPatient,
            OtherCarer: reviewerInfo.OtherCarer,
            Groups: reviewerInfo.Groups,
            CountryOfResidenceKey: reviewerInfo.CountryOfResidenceKey,
            OtherCountry: reviewerInfo.OtherCountry,
            YearOfBirthKey: reviewerInfo.YearOfBirthKey,
            Disability: reviewerInfo.Disability,
            Lifestyle: reviewerInfo.Lifestyle,
            OccupationYesNo: reviewerInfo.OccupationYesNo,
            CurrentOccupation: reviewerInfo.CurrentOccupation,
            PublicSector: reviewerInfo.PublicSector,
            AnythingElse: reviewerInfo.AnythingElse,
            NotProfessionalTick: reviewerInfo.NotProfessionalTick,
            NewsAndEventsTick: reviewerInfo.NewsAndEventsTick,
            ReviewApplicationTick: reviewerInfo.ReviewApplicationTick,
            ShareDataCheckbox: reviewerInfo.ShareDataCheckbox,
            ResponseFlag: reviewerInfo.ResponseFlag,
            CcfOnly: reviewerInfo.CcfOnly,
            Notes: reviewerInfo.Notes,
        });
    }

    updateReviewerData(formData: any): any {
        var userkey = this.reviewerInfo.User.UserKey;
        formData.UserKey = userkey;

        this.formService.post(Global.BASE_FORM_ENDPOINT + "updatereviewerdata", formData.value).subscribe(
            data => {
                if (data.Information === "Form Updated") {
                    this.msg = "Reviewer Information Updated";
                    this.reviewerData();
                    this.activeModal.close();
                } else {
                    this.msg = "Form not saved please contact the admin team";
                }
            },
            error => this.msg = <any>error);
    }

    convertToCsv(exportReviewer: any[]) {
        var str = '';
        var row = "";

        for (var index in exportReviewer[0]) {
            //Now convert each value to string and comma-separated
            row += index + ',';
        }
        row = row.slice(0, -1);
        //append Label row with line break
        str += row + '\r\n';

        for (var i = 0; i < exportReviewer.length; i++) {
            var line = '';
            for (var index in exportReviewer[i]) {
                if (line != '') {
                    line += ',';
                }

                switch (index) {
                    case "Title":
                    case "FirstName":
                    case "Surname":
                    case "EmailAddress":
                    case "DependentChildren":
                    case "BloodPatient":
                    case "BloodCarer":
                    case "CancerPatient":
                    case "CancerCarer":
                    case "CardioPatient":
                    case "CardioCarer":
                    case "CongenitialPatient":
                    case "CongenitialCarer":
                    case "EarPatient":
                    case "EarCarer":
                    case "EyePatient":
                    case "EyeCarer":
                    case "InfectionPatient":
                    case "InfectionCarer":
                    case "InflammatoryAndImmunePatient":
                    case "InflammatoryAndImmuneCarer":
                    case "InjuriesAndAccidentsPatient":
                    case "InjuriesAndAccidentsCarer":
                    case "MentalHealthPatient":
                    case "MentalHealthCarer":
                    case "MetabolicAndEndocrimePatient":
                    case "MetabolicAndEndocrimeCarer":
                    case "MusculoSkeletalPatient":
                    case "MusculoSkeletalCarer":
                    case "NeurologicalPatient":
                    case "NeurologicalCarer":
                    case "OralAndGastroPatient":
                    case "OralAndGastroCarer":
                    case "RenalAndUrogenitalPatient":
                    case "RenalAndUrogenitalCarer":
                    case "ReproductiveHealthAndChildbirthPatient":
                    case "ReproductiveHealthAndChildbirthCarer":
                    case "RespiratoryPatient":
                    case "RespiratoryCarer":
                    case "SkinPatient":
                    case "SkinCarer":
                    case "StrokePatient":
                    case "StrokeCarer":
                    case "GenericHealthPatient":
                    case "GenericHealthCarer":
                    case "OtherPatient":
                    case "OtherCarer":
                    case "Groups":
                    case "Country":
                    case "OtherCountry":
                    case "Date":
                    case "Disability":
                    case "Lifestyle":
                    case "OccupationYesNo":
                    case "CurrentOccupation":   
                    case "PublicSector":
                    case "AnythingElse":
                    case "Notes":
                    case "CreatedDate":
                    case "UpdatedDate":
                    case "YearOfBirth":
                    

                        let item = exportReviewer[i][index];

                        if (index == "YearOfBirth" || index == "Country") {
                            item = item.Description;
                        }

                        switch (item) {
                            case undefined:
                            case null:
                                break;
                            default:
                                let cell = item.replace(/"/g, "'");
                                line += '"' + cell + '"';
                        }

                        break;
                    default:
                        line += exportReviewer[i][index];
                        break;
                }

            }
            str += line + '\r\n';
        }
        return str;
    }

    download() {
        this.exportReviewers = [];

        for (let info of this.reviewerInfos) {
            if (info.User.IsActive) {
                let exportReviewer: ExportReviewer = {
                    Title: info.Title,
                    FirstName: info.FirstName,
                    Surname: info.Surname,
                    EmailAddress: info.User.EmailAddress,
                    DependentChildren: info.DependentChildren,
                    BloodPatient: info.BloodPatient,
                    BloodCarer: info.BloodCarer,
                    CancerPatient: info.CancerPatient,
                    CancerCarer: info.CancerCarer,
                    CardioPatient: info.CardioPatient,
                    CardioCarer: info.CardioCarer,
                    CongenitialPatient: info.CongentialPatient,
                    CongenitialCarer: info.CongentialCarer,
                    EarPatient: info.EarPatient,
                    EarCarer: info.EarCarer,
                    EyePatient: info.EyePatient,
                    EyeCarer: info.EarCarer,
                    InfectionPatient: info.InfectionPatient,
                    InfectionCarer: info.InfectionCarer,
                    InflammatoryAndImmunePatient: info.InflammatoryAndImmunePatient,
                    InflammatoryAndImmuneCarer: info.InflammatoryAndImmuneCarer,
                    InjuriesAndAccidentsPatient: info.InjuriesAndAccidentsPatient,
                    InjuriesAndAccidentsCarer: info.InjuriesAndAccidentsCarer,
                    MentalHealthPatient: info.MentalHealthPatient,
                    MentalHealthCarer: info.MentalHealthCarer,
                    MetabolicAndEndocrimePatient: info.MetabolicAndEndocrimePatient,
                    MetabolicAndEndocrimeCarer: info.MetabolicAndEndocrimeCarer,
                    MusculoSkeletalPatient: info.MusculoSkeletalPatient,
                    MusculoSkeletalCarer: info.MusculoSkeletalCarer,
                    NeurologicalPatient: info.NeurologicalPatient,
                    NeurologicalCarer: info.NeurologicalCarer,
                    OralAndGastroPatient: info.OralAndGastroPatient,
                    OralAndGastroCarer: info.OralAndGastroCarer,
                    RenalAndUrogenitalPatient: info.RenalAndUrogenitalPatient,
                    RenalAndUrogenitalCarer: info.RenalAndUrogentialCarer,
                    ReproductiveHealthAndChildbirthPatient: info.ReproductiveHealthAndChildbirthPatient,
                    ReproductiveHealthAndChildbirthCarer: info.ReproductiveHealthAndChildbirthCarer,
                    RespiratoryPatient: info.RespiratoryPatient,
                    RespiratoryCarer: info.RespiratoryCarer,
                    SkinPatient: info.SkinPatient,
                    SkinCarer: info.SkinCarer,
                    StrokePatient: info.StrokePatient,
                    StrokeCarer: info.StrokeCarer,
                    GenericHealthPatient: info.GenericHealthPatient,
                    GenericHealthCarer: info.GenericHealthCarer,
                    OtherPatient: info.OtherPatient,
                    OtherCarer: info.OtherCarer,
                    Groups: info.Groups,
                    Country: info.CountryOfResidenceKey,
                    OtherCountry: info.OtherCountry,
                    YearOfBirth: info.YearOfBirthKey,
                    Disability: info.Disability,
                    Lifestyle: info.Lifestyle,
                    OccupationYesNo: info.OccupationYesNo,
                    CurrentOccupation: info.CurrentOccupation,
                    PublicSector: info.PublicSector,
                    AnythingElse: info.AnythingElse,
                    Notes: info.Notes,
                    CreatedDate: info.CreatedDate,
                    UpdatedDate: info.UpdatedDate,
                    IsReminderEmailSent: info.User.IsReminderEmailSent,
                    ReminderEmailDateTime: info.User.ReminderEmailDateTime
                };

                this.exportReviewers.push(exportReviewer);
            }
        }

        var csvData = this.convertToCsv(this.exportReviewers);
        var a = document.createElement("a");
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        var blob = new Blob([csvData], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Reviewer_Data.csv';/* your file name*/
        a.click();
        return 'success';
    }

    open(content: any) {
        this.activeModal =
            this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: "static", keyboard: false });
    }

    compare(val1: any, val2: any) {
        return val1.Name === val2.Name;
    }

    assignCopy() {
        this.filteredReviewerInfos = Object.assign([], this.reviewerInfos);
    }

    filter(value: any) {
        if (!value) {
            this.assignCopy();
        }
        this.filteredReviewerInfos = Object.assign([], this.reviewerInfos).filter(
            reviewerInfo => JSON.stringify(reviewerInfo).toLowerCase().indexOf(value.toLowerCase()) > -1
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }
}
