import { ReviewerMatch } from "./reviewerMatch.Model";
import { IUser } from "./user.model"
import { ICountryOfResidence } from "../Enum/countryOfResidence.model"
import { IYearOfBirth } from "../Enum/yearOfBirth.Model"


export class ApplicantDetails {
    ApplicantKey: number;
    ApplicantIdentifier: string;
    Title: string;
    FirstName: string;
    Surname: string;
    YearOfBirth: number;
    DependentChildren: boolean;
    EmailAddress: string;
    Password: string;
    PasswordConfirm: string;
    RefNumber: string;
    CompetitionKey: number;
    FundedFlag: boolean;
    ResponseFlag: boolean;
    ReminderAttempts: number;
    EmailSent: boolean;
    BloodPatient: string;
    BloodCarer: string;
    CancerPatient: string;
    CancerCarer: string;
    CardioPatient: string;
    CardioCarer: string;
    CongentialPatient: string;
    CongentialCarer: string;
    EarPatient: string;
    EarCarer: string;
    EyePatient: string;
    EyeCarer: string;
    InfectionPatient: string;
    InfectionCarer: string;
    InflammatoryAndImmunePatient: string;
    InflammatoryAndImmuneCarer: string;
    InjuriesAndAccidentsPatient: string;
    InjuriesAndAccidentsCarer: string;
    MentalHealthPatient: string;
    MentalHealthCarer: string;
    MetabolicAndEndocrimePatient: string;
    MetabolicAndEndocrimeCarer: string;
    MusculoSkeletalPatient: string;
    MusculoSkeletalCarer: string;
    NeurologicalPatient: string;
    NeurologicalCarer: string;
    OralAndGastroPatient: string;
    OralAndGastroCarer: string;
    RenalAndUrogenitalPatient: string;
    RenalAndUrogentialCarer: string;
    ReproductiveHealthAndChildbirthPatient: string;
    ReproductiveHealthAndChildbirthCarer: string;
    RespiratoryPatient: string;
    RespiratoryCarer: string;
    SkinPatient: string;
    SkinCarer: string;
    StrokePatient: string;
    StrokeCarer: string;
    GenericHealthPatient: string;
    GenericHealthCarer: string;
    OtherPatient: string;
    OtherCarer: string;
    Groups: string;
    AnythingElse: string;
    CountryOfResidenceKey: ICountryOfResidence;
    OtherCountry: string;
    YearOfBirthKey: IYearOfBirth;
    Disability: string;
    Lifestyle: string;
    OccupationYesNo: string;
    CurrentOccupation: string;
    PublicSector: string;
    NotProfessionalTick: boolean;
    NewsAndEventsTick: boolean;
    ReviewApplicationTick: boolean;
    ShareDataCheckbox: boolean;
    CcfOnly: boolean;
    Notes: string;
    CreatedDate: Date;
    UpdatedDate: Date;
    User: IUser;
}