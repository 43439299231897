import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Global } from "../Shared/global";

import { ICentre } from "../Enum/centre.model";
import { IRole } from "../Enum/role.model";
import { IYearOfBirth } from "../Enum/yearOfBirth.model";
import { ICountryOfResidence } from "../Enum/countryOfResidence.model";

import { ReviewerData } from "../Model/reviewerData.Model"



@Injectable()
export class ResourceService {
   
    constructor(private http: HttpClient) { }

    private resourceBaseUrl = Global.BASE_RESOURCE_ENDPOINT;
    private getDatesUrl = this.resourceBaseUrl + "getdates";
    private getCountryUrl = this.resourceBaseUrl + "getcountry";
    private getCentresUrl = this.resourceBaseUrl + "getcentres";
    private getRolesUrl = this.resourceBaseUrl + "getroles";
    private ReviewerDataUrl = this.resourceBaseUrl + "getreviewerdata";
    private NetscDataUrl = this.resourceBaseUrl + "getnetsccdata";

    getDates(): Observable<IYearOfBirth[]> {
        return this.http.get<IYearOfBirth[]>(this.getDatesUrl)
            .pipe(
                tap(dates => this.log(`returned dates`)),
                catchError(this.handleError('getdates', []))
            );
    }

    getCountry(): Observable<ICountryOfResidence[]> {
        return this.http.get<ICountryOfResidence[]>(this.getCountryUrl)
            .pipe(
                tap(countries => this.log(`returned countries`)),
                catchError(this.handleError('getcountry', []))
            );
    }

    getCentres(): Observable<ICentre[]> {
        return this.http.get<ICentre[]>(this.getCentresUrl)
            .pipe(
                tap(centres => this.log(`returned centres`)),
                catchError(this.handleError('getcentre', []))
            );
    }

    getRoles(): Observable<IRole[]> {
        return this.http.get<IRole[]>(this.getRolesUrl)
            .pipe(
                tap(roles => this.log(`fetched roles`)),
                catchError(this.handleError('getroles', []))
            );
    }

    reviewerData(): Observable<ReviewerData[]> {
        return this.http.get<ReviewerData[]>(this.ReviewerDataUrl)
            .pipe(
                tap(data => this.log('return all reviewers data')),
                catchError(this.handleError('reviewerdata', []))
            );
    }

    NETSCCData(): Observable<ReviewerData[]> {
        return this.http.get<ReviewerData[]>(this.NetscDataUrl)
            .pipe(
                tap(data => this.log('return Netsc Data only')),
                catchError(this.handleError('netscdata', []))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }
}