import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Global } from "../Shared/global";
import { ApplicantDetails } from "../Model/applicantDetails.model";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class FormService {
    constructor(private http: HttpClient) { }

    private formBaseUrl = Global.BASE_FORM_ENDPOINT;
    private checkUserExistsUrl = this.formBaseUrl + "checkUserExists";
    private formLoginUrl = this.formBaseUrl + "formLogin";
    private getUserDetailsUrl = this.formBaseUrl + "returnuserdetails";

    post(url: any, formData: any): Observable<any> {
        return this.http.post<any>(url, formData)
            .pipe(
                tap((data: any) => this.log(`there was an error` + url)),
                catchError(this.handleError<any>('error with ' + url))
            );
    }

    get(url: string) {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get(url, { headers: headers, observe: 'response' });
    }

    checkUserExists(emailAddress: string): Observable<any> {
        return this.http.post<any>(this.checkUserExistsUrl, JSON.stringify(emailAddress), httpOptions)
            .pipe(
                tap(data => this.log(`returned records check`)),
            catchError(this.handleError('checkUserExists', []))
            );
    }

    getUserDetails(userKey: any): Observable<any> {
        return this.http.post<any>(this.getUserDetailsUrl, JSON.stringify(userKey), httpOptions)
            .pipe(
                tap(data => this.log('returned user record')),
            catchError(this.handleError('getuserdetails', []))
            );
    }

    

    formLogin(applicantDetails: any): Observable<any> {
        return this.http.post<any>(this.formLoginUrl, applicantDetails, httpOptions)
            .pipe(
                tap((data: any) => this.log(`Returned check details result`)),
            catchError(this.handleError<ApplicantDetails>('formLogin'))
            );
    }

   


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }
}