import { Injectable, Output, EventEmitter, } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Global } from "../Shared/global";
import { IUser } from '../Model/user.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LoginService {
    constructor(private http: HttpClient) { }
    @Output() getLoggedInStatus: EventEmitter<any> = new EventEmitter();

    private loginBaseUrl = Global.BASE_LOGIN_ENDPOINT;
    private changePasswordUrl = this.loginBaseUrl + "changepassword";
    private checkUserDetailsUrl = this.loginBaseUrl + "first";
    private forgotPasswordUrl = this.loginBaseUrl + "forgotpassword";

    isLoggedIn(): Observable<boolean> {
        var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        if (currentUser) {
            this.getLoggedInStatus.emit(true);
            return (true) as any;
        } else {
            this.getLoggedInStatus.emit(false);
            return (false) as any;
        }
    }

    changeUserPassword(user: IUser): Observable<any> {
        return this.http.post<any>(this.changePasswordUrl, user, httpOptions)
            .pipe(
                tap((data: any) => this.log(`User Password Changed`)),
                catchError(this.handleError<IUser>('changePassword'))
            );
    }

    checkDetails(formData: any): Observable<any> {
        return this.http.post<any>(this.checkUserDetailsUrl, formData, httpOptions)
            .pipe(
                tap((data: any) => this.log(`Returned check details result`)),
                catchError(this.handleError<IUser>('first'))
            );
    }

    forgotPassword(formData: any): Observable<any> {
        return this.http.post<any>(this.forgotPasswordUrl, formData, httpOptions)
            .pipe(
                tap((data: any) => this.log(`Returned forgotpassword`)),
                catchError(this.handleError<IUser>('forgotpassword'))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //hook to slack
    }

}
