import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
  templateUrl: 'Template/footer.component.html',
  styleUrls: ['Style/footer.component.css']
})
export class FooterComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
