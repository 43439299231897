////comments
//Home page showing information text and then a login box.Login is email address.When the email address has been entered the system will go to the database and check if the user exists.
//If user exists a password box will pop up.After password added it will check the role and if admin or ccf or nets then it will send to the correct page.
//If its a reviewer it will pull information and populate the form. Reviewer can check and resave there information
//If user is a new user, the form will be blank. User can save information after password/confirm password placed in the box.
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Global } from "../../Shared/global";
import { ResourceService } from "../../Service/resource.service";
import { LoginService } from "../../Service/login.service"
import { AuthService } from '../../Service/auth.service';
import { FormService } from '../../Service/form.service';
import { UserService } from '../../Service/user.service';
import { ICredentials } from "../../Model/credentials";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewerMatch } from '../../model/reviewermatch.model';
import { ApplicantDetails } from "../../model/applicantDetails.model";
import { MatExpansionModule, MatInputModule } from '@angular/material';

import { IYearOfBirth } from "../../Enum/yearOfBirth.model";
import { ICountryOfResidence } from "../../Enum/countryOfResidence.model";

@Component({
    selector: 'app-home',
    templateUrl: 'Template/home.component.html',
    styleUrls: ['Style/home.component.css']
})
export class HomeComponent implements OnInit {

    activeModal: NgbActiveModal;
    changePasswordFrm: FormGroup;
    forgotPasswordFrm: FormGroup;
    reviewerMatchFrm: FormGroup;
    passwordFrm: FormGroup;
    loginFrm: any;
    panelOpenState = false;

    dates: IYearOfBirth[];
    countries: ICountryOfResidence[];

    reviewerMatch: ReviewerMatch[];
    returnedData: ApplicantDetails;
    credentials: ICredentials;
    userPassword: string;
    userEmail: string;
    emailAddress: string;
    password: string;
    isLoading: boolean = false;
    showPassword: boolean = false;
    loginPassword: boolean = false;
    showFields: boolean = false;
    changeButtons: boolean = true;
    showErrorMessage: boolean = false;
    showSaveErrorMessage: boolean = false;
    showErrorEmailMessage: boolean = false;
    showBoxErrorMessage: boolean = false;
    showPasswordError: boolean = false;
    showBoxPasswordChange: boolean = false;
    showIncorrectLoginDetails: boolean = false;
    isLoggedIn: boolean;
    msg: string;
    modelMsg: string;
    indLoading: boolean = false;
    customCollapsedHeight: string = '120px';
    isDisabled: boolean = true;

    parsedResponse: any;

    formInfo: ApplicantDetails = new ApplicantDetails;
    formInfos: ApplicantDetails[];
    applicantDetails: ApplicantDetails = new ApplicantDetails;
    userDetails: ApplicantDetails = new ApplicantDetails;

    constructor(private fb: FormBuilder,
        private loginService: LoginService,
        private resourceService: ResourceService,
        private authService: AuthService,
        private formService: FormService,
        private userService: UserService,
        private router: Router,
        private modalService: NgbModal
    ) {
    }

    ngOnInit(): void {
        if (this.authService.isLoggedIn()) {
            var currentUserRole = sessionStorage.getItem("UserRole");
            if (currentUserRole === "Admin") {
                this.router.navigate(['/admin']);
            } else {
                this.router.navigate(['']);
            }

            var currentUserCentre = sessionStorage.getItem("UserRole");
            if (currentUserCentre === "CCF" || currentUserCentre === "NETSCC") {
                this.router.navigate(['/data']);
            }
            
            }

        this.createLoginFrm();
        this.createPasswordFrm();
        this.createForgotPasswordFrm();
        this.createChangePasswordFrm();
        this.createReviewerMatchFrm();
        this.getDates();
        this.getCountries();
    }

    createLoginFrm() {
        this.loginFrm = this.fb.group({
            EmailAddress: ["", Validators.required],
            EmailAddressConfirm: "",
            Password: ["", Validators.required],
            });
    }

    createChangePasswordFrm() {
        this.changePasswordFrm = this.fb.group({
            EmailAddress: "",
            OldPassword: "",
            NewPassword: ["", Validators.required],
            ConfirmPassword: ["", Validators.required]
        });
    }

    createForgotPasswordFrm() {
        this.forgotPasswordFrm = this.fb.group({
            EmailAddress: ["", Validators.required],
        });
    }

    createPasswordFrm() {
        this.passwordFrm = this.fb.group({
            LoginNewPassword: ["", Validators.required],
            LoginConfirmPassword: ["", Validators.required],

        });
    }

    getDates() {
        this.resourceService.getDates().subscribe(
            data => {
                this.dates = data;
            },
            error => this.msg = <any>error);
    }

    getCountries() {
        this.resourceService.getCountry().subscribe(
            data => {
                this.countries = data;
            },
            error => this.msg = <any>error);
    }

    createReviewerMatchFrm() {
        this.reviewerMatchFrm = this.fb.group(({
            Title: ["", Validators.required],
            FirstName: ["", Validators.required],
            Surname: ["", Validators.required],
            DependentChildren: ["", Validators.required],
            BloodPatient: "",
            BloodCarer: "",
            CancerPatient: "",
            CancerCarer: "",
            CardioPatient: "",
            CardioCarer: "",
            CongentialPatient: "",
            CongentialCarer: "",
            EarPatient: "",
            EarCarer: "",
            EmailAddress: "",
            EyePatient: "",
            EyeCarer: "",
            InfectionPatient: "",
            InfectionCarer: "",
            InflammatoryAndImmunePatient: "",
            InflammatoryAndImmuneCarer: "",
            InjuriesAndAccidentsPatient: "",
            InjuriesAndAccidentsCarer: "",
            MentalHealthPatient: "",
            MentalHealthCarer: "",
            MetabolicAndEndocrimePatient: "",

            MetabolicAndEndocrimeCarer: "",
            MusculoSkeletalPatient: "",
            MusculoSkeletalCarer: "",
            NeurologicalPatient: "",
            NeurologicalCarer: "",
            OralAndGastroPatient: "",
            OralAndGastroCarer: "",
            RenalAndUrogenitalPatient: "",
            RenalAndUrogentialCarer: "",
            ReproductiveHealthAndChildbirthPatient: "",
            ReproductiveHealthAndChildbirthCarer: "",
            RespiratoryPatient: "",
            RespiratoryCarer: "",
            SkinPatient: "",
            SkinCarer: "",
            StrokePatient: "",
            StrokeCarer: "",
            GenericHealthPatient: "",
            GenericHealthCarer: "",
            OtherPatient: "",
            OtherCarer: "",
            Groups: "",
            AnythingElse: "",
            CountryOfResidenceKey: ["", Validators.required],
            OtherCountry: "",
            YearOfBirthKey: ["", Validators.required],
            Disability: "",
            Lifestyle: "",
            OccupationYesNo: ["", Validators.required],
            CurrentOccupation: "",
            PublicSector: "",
            NotProfessionalTick: "",
            NewsAndEventsTick: "",
            ReviewApplicationTick: ["", Validators.required],
            ShareDataCheckbox: "",
            Password: "",
            PasswordConfirm: "",
            LoginPassword: "",
            LoginPasswordConfirm: "",
            User: {}
        }));
    }

    open(content: any, modal?: any) {
        this.activeModal =
            this.modalService.open(content,
                { ariaLabelledBy: 'modal-basic-title', backdrop: "static", keyboard: false });
    }

    checkUserExists(content: any): void {
        var emailAddress = this.loginFrm.get("EmailAddress").value;

        if (emailAddress) {

            this.formService.checkUserExists(emailAddress).subscribe(
                data => {
                    if (data.Successful) {
                        this.loginPassword = true;
                        this.showFields = false;
                        this.showPassword = false;
                    } else {
                        this.loginPassword = false;
                        this.showPassword = true;
                        this.showFields = true;

                    }
                },
                error => this.msg = <any>error);
        }
    }


    updateReviewerMatchFrm(formInfo) {
        this.applicantDetails = formInfo;
        this.reviewerMatchFrm.patchValue({
            User: formInfo.User,
            Title: this.applicantDetails.Title,
            FirstName: this.applicantDetails.FirstName,
            Surname: this.applicantDetails.Surname,
            YearOfBirth: this.applicantDetails.YearOfBirth,
            DependentChildren: this.applicantDetails.DependentChildren,
            EmailAddress: this.applicantDetails.EmailAddress,
            BloodPatient: this.applicantDetails.BloodPatient,
            BloodCarer: this.applicantDetails.BloodCarer,
            CancerPatient: this.applicantDetails.CancerPatient,
            CancerCarer: this.applicantDetails.CancerCarer,
            CardioPatient: this.applicantDetails.CardioPatient,
            CardioCarer: this.applicantDetails.CardioCarer,
            CongentialPatient: this.applicantDetails.CongentialPatient,
            CongentialCarer: this.applicantDetails.CongentialCarer,
            EarPatient: this.applicantDetails.EarPatient,
            EarCarer: this.applicantDetails.EarCarer,
            EyePatient: this.applicantDetails.EyePatient,
            EyeCarer: this.applicantDetails.EyeCarer,
            InfectionPatient: this.applicantDetails.InfectionPatient,
            InfectionCarer: this.applicantDetails.InfectionCarer,
            InflammatoryAndImmunePatient: this.applicantDetails.InflammatoryAndImmunePatient,
            InflammatoryAndImmuneCarer: this.applicantDetails.InflammatoryAndImmuneCarer,
            InjuriesAndAccidentsPatient: this.applicantDetails.InjuriesAndAccidentsPatient,
            InjuriesAndAccidentsCarer: this.applicantDetails.InjuriesAndAccidentsCarer,
            MentalHealthPatient: this.applicantDetails.MentalHealthPatient,
            MentalHealthCarer: this.applicantDetails.MentalHealthCarer,
            MetabolicAndEndocrimePatient: this.applicantDetails.MetabolicAndEndocrimePatient,
            MetabolicAndEndocrimeCarer: this.applicantDetails.MetabolicAndEndocrimeCarer,
            MusculoSkeletalPatient: this.applicantDetails.MusculoSkeletalPatient,
            MusculoSkeletalCarer: this.applicantDetails.MusculoSkeletalCarer,
            NeurologicalPatient: this.applicantDetails.NeurologicalPatient,
            NeurologicalCarer: this.applicantDetails.NeurologicalCarer,
            OralAndGastroPatient: this.applicantDetails.OralAndGastroPatient,
            OralAndGastroCarer: this.applicantDetails.OralAndGastroCarer,
            RenalAndUrogenitalPatient: this.applicantDetails.RenalAndUrogenitalPatient,
            RenalAndUrogentialCarer: this.applicantDetails.RenalAndUrogentialCarer,
            ReproductiveHealthAndChildbirthPatient: this.applicantDetails.ReproductiveHealthAndChildbirthPatient,
            ReproductiveHealthAndChildbirthCarer: this.applicantDetails.ReproductiveHealthAndChildbirthCarer,
            RespiratoryPatient: this.applicantDetails.RespiratoryPatient,
            RespiratoryCarer: this.applicantDetails.RespiratoryCarer,
            SkinPatient: this.applicantDetails.SkinPatient,
            SkinCarer: this.applicantDetails.SkinCarer,
            StrokePatient: this.applicantDetails.StrokePatient,
            StrokeCarer: this.applicantDetails.SkinCarer,
            GenericHealthPatient: this.applicantDetails.GenericHealthPatient,
            GenericHealthCarer: this.applicantDetails.GenericHealthCarer,
            OtherPatient: this.applicantDetails.OtherPatient,
            OtherCarer: this.applicantDetails.OtherCarer,
            Groups: this.applicantDetails.Groups,
            AnythingElse: this.applicantDetails.AnythingElse,
            NotProfessionalTick: this.applicantDetails.NotProfessionalTick,
            NewsAndEventsTick: this.applicantDetails.NewsAndEventsTick,
            ReviewApplicationTick: this.applicantDetails.ReviewApplicationTick,
            ShareDataCheckbox: this.applicantDetails.ShareDataCheckbox,
            CcfOnly: this.applicantDetails.CcfOnly,
            CountryOfResidenceKey: this.applicantDetails.CountryOfResidenceKey,
            OtherCountry: this.applicantDetails.OtherCountry,
            YearOfBirthKey: this.applicantDetails.YearOfBirthKey,
            Disability: this.applicantDetails.Disability,
            Lifestyle: this.applicantDetails.Lifestyle,
            OccupationYesNo: this.applicantDetails.OccupationYesNo,
            CurrentOccupation: this.applicantDetails.CurrentOccupation,
            PublicSector: this.applicantDetails.PublicSector,
        });
    }

    //this.authService.logout();
    //    this.isLoggedIn = false;


    login(formData: any, content: any): void {
        this.showIncorrectLoginDetails = false;
        this.showBoxPasswordChange = false;
            this.loginService.checkDetails(formData.value).subscribe(
                data => {
                    if (data.Successful) {
                        if (data.Information === "IsFirstTime") {
                            this.changePasswordFrm.patchValue({
                                EmailAddress: formData.value.EmailAddress,
                                OldPassword: formData.value.Password,
                            });

                            this.emailAddress = formData.value.EmailAddress;
                            this.password = formData.value.Password;
                            this.activeModal = this.modalService.open(content,
                                { ariaLabelledBy: 'modal-basic-title', backdrop: "static", keyboard: false });
                        } else {
                            //this.activeModal.close();
                            this.authService.login(formData.value).subscribe(
                                (response: Response) => {
                                    this.parsedResponse = JSON.parse(String(response));
                                    let token = this.parsedResponse.Token;
                                    let userRole = this.parsedResponse.UserRole;
                                    let userCentre = this.parsedResponse.UserCentre;
                                    let username = this.parsedResponse.Username;
                                    let userKey = this.parsedResponse.UserKey;

                                    if (token) {
                                        sessionStorage.setItem("currentUser",
                                            JSON.stringify({
                                                userKey: userKey,
                                                username: username,
                                                userRole: userRole,
                                                userCentre: userCentre,
                                                token: token
                                            }));


                                        this.isLoggedIn = true;
                                        this.loginService.isLoggedIn();
                                        //this.activeModal.close();
                                        if (userRole === "Admin") {
                                            this.router.navigate(["/admin"]);
                                        }

                                        else if (userRole === "Center" && (userCentre === "CCF" || userCentre === "NETSCC")) {
                                            this.router.navigate(["/data"]);
                                        } else {
                                            this.applicantDetails.Password = formData.value.Password;
                                            this.applicantDetails.EmailAddress = formData.value.EmailAddress;


                                            this.formService.formLogin(this.applicantDetails).subscribe(
                                                data => {
                                                    if (data.Successful) {
                                                        this.msg = "Password Correct";
                                                        this.loginPassword = false;
                                                        this.showFields = true;
                                                        this.reviewerMatchFrm.patchValue({
                                                            LoginPassword: "*****",
                                                        });
                                                        this.retrieveUserDetails(userKey);
                                                    } else {
                                                        this.msg = "Password incorrect, Please try again";
                                                    }
                                                },
                                                error => this.msg =
                                                "There was a system error please contact your system administrator");
                                        }
                                    }
                                });
                        }
                    } else {
                        this.showIncorrectLoginDetails = true;
                        this.indLoading = false;
                    }
                },
                error => this.msg = error);
    }

    changeFirstTimePassword(formData: any): void {
        this.loginService.changeUserPassword(formData.value).subscribe(
            data => {
                if (data.Successful) {
                    this.modelMsg = data.Information;
                    this.createLoginFrm();
                    this.activeModal.close();
                    this.showBoxPasswordChange = true;
                    this.indLoading = false;
                } else {
                    this.modelMsg = data.Information;
                }
            },
            error => this.modelMsg = error);
    }

    forgotUserPassword(formData: any): void {
        this.loginService.forgotPassword(formData.value).subscribe(
            (data): void => {

                if (data.Successful) {
                    this.modelMsg = "Email sent Successfully";
                    this.activeModal.close();
                } else if (!data.Successful) {
                    this.modelMsg = "Email could not be sent contact Administrator";
                }

            },
            error => this.modelMsg = "There was a system error please contact your system administrator");

    }

    //loginFormRetrieval() {
    //    this.applicantDetails.Password = this.reviewerMatchFrm.get("LoginPassword").value;
    //    this.applicantDetails.EmailAddress = this.reviewerMatchFrm.get("EmailAddress").value;

    //    this.formService.formLogin(this.applicantDetails).subscribe(
    //        data => {
    //            if (data.Successful) {
    //                this.msg = "Password Correct";
    //                this.loginPassword = false;
    //                this.reviewerMatchFrm.patchValue({
    //                    LoginPassword: "*****",
    //            });
    //                this.retrieveUserDetails();
    //            } else {
    //                this.msg = "Password incorrect, Please try again";
    //            }
    //        },
    //        error => this.msg = "There was a system error please contact your system administrator");
    //}

    clearMsg(): void {
        this.msg = null;
        this.modelMsg = null;
    }

    retrieveUserDetails(userKey): void {
        this.formService.getUserDetails(userKey).subscribe(
            userDetails => {
                this.loginPassword = false;
                var formInfo = userDetails;
                this.changeButtons = false;
                this.isDisabled = false;
                this.updateReviewerMatchFrm(formInfo);
            });
    }

    saveReviewerMatch(formData: any): any {
        this.showErrorEmailMessage = false;
        this.showBoxErrorMessage = false;
        this.showPasswordError = false;

        var emailAddress = this.loginFrm.get("EmailAddress").value;
        var emailConfirm = this.loginFrm.get("EmailAddressConfirm").value;
        var password = this.reviewerMatchFrm.get("Password").value;
        var confirmPassword = this.reviewerMatchFrm.get("PasswordConfirm").value;

        var newsBox = this.reviewerMatchFrm.get("NewsAndEventsTick").value;
        var reviewBox = this.reviewerMatchFrm.get("ReviewApplicationTick").value;

        if (emailAddress === emailConfirm && password === confirmPassword && newsBox || reviewBox === true) { 
            this.showErrorEmailMessage = false;
            this.showBoxErrorMessage = false;
               
            formData.CcfOnly = false;
            formData.EmailAddress = emailAddress;

            this.formService.post(Global.BASE_FORM_ENDPOINT + "saveform", formData).subscribe(
                data => {
                    if (data.Information === "Form saved") {
                        this.router.navigate(["/thanks"]);
                    } else {
                        this.msg = "Form not saved please contact the admin team";
                    }
                },
                error => this.modelMsg = <any>error);
        } else {
            if (newsBox === false && reviewBox === false) {
                this.showBoxErrorMessage = true;
            } else {
                if (emailAddress !== emailConfirm) {
                    this.showErrorEmailMessage = true;
                } else {
                    if (password !== confirmPassword) {
                        this.showPasswordError = true;
                    }
                }
            }   
            
        }

    }

    updateForm(formData: any): any {
        this.showBoxErrorMessage = false;
        var newsBox = this.reviewerMatchFrm.get("NewsAndEventsTick").value;
        var reviewBox = this.reviewerMatchFrm.get("ReviewApplicationTick").value;
        if (newsBox || reviewBox === true) {
            this.showSaveErrorMessage = false;
            this.showBoxErrorMessage = false;

            var userkey = this.applicantDetails.User.UserKey;
            formData.UserKey = userkey;
            

            var emailAddress = this.loginFrm.get("EmailAddress").value;

            formData.EmailAddress = emailAddress;

            this.formService.post(Global.BASE_FORM_ENDPOINT + "updateform", formData).subscribe(
                data => {
                    if (data.Information === "Form saved") {
                        this.router.navigate(["/update"]);
                    } else {
                        this.msg = "Form not updated please contact the admin team";
                    }
                },
                error => this.modelMsg = <any>error);

        } else {

            this.showBoxErrorMessage = true;
        }
    }

    compare(val1: any, val2: any) {
        return val1.Name === val2.Name;
    }

}
