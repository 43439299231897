import { Routes, RouterModule, CanActivate } from "@angular/router";
import { HomeComponent } from "./Components/Home/home.component";
import { AccountManagementComponent } from "./Components/AccountManagement/accountManagement.component";
import { ReviewMatchDataComponent } from "./Components/reviewMatchData/reviewMatchData.component";
import { ThanksComponent } from "./Components/Thanks/thanks.component";
import { UpdateComponent } from "./Components/Update/update.component";
import { RepairComponent } from "./Components/Repair/repair.component";
import { AuthGuard } from "./Service/auth-guard.service";

const appRoutes: Routes = [
    { path: "", redirectTo: "home", pathMatch: "full" },
    { path: "home", component: HomeComponent },
    { path: "admin", component: AccountManagementComponent, canActivate: [AuthGuard] },
    { path: "data", component: ReviewMatchDataComponent, canActivate: [AuthGuard] },
    { path: "thanks", component: ThanksComponent },
    { path: "update", component: UpdateComponent },
    //{ path: "repair", component: RepairComponent }
];

export const appRoutingModule =
  RouterModule.forRoot(appRoutes, { useHash: true });
